import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import styles from './index.module.scss';
import { EventBus } from 'events/EventBus';
import { ReaderEvent, ReaderToolsEvent } from 'events/EventTypes';
import { ModalComponentType } from 'components/common/Modal/ModalComponentGenerator';
import { useStore, StoreTypes } from 'context';

export const CanvasSVGObjectView = ({ isActive, annotationId, viewIndex, svgString }) => {
    const [{ svgContent }] = useStore(StoreTypes.extendedContent);
    const reducers = useStore();
    const canvasSVGRef = useRef();
    // const [{ undoAnnotations }] = reducers[StoreTypes.canvas];
    const { userAgent } = navigator;

    useEffect(() => {
        const node = ReactDOM.findDOMNode(canvasSVGRef.current);
        if (!node) return;
        const groups = node.querySelectorAll('g');
        groups.forEach(group => {
            if (group.id) {
                if (group.id === ModalComponentType.StickyNote || group.id === ModalComponentType.Whiteboard || group.id === ModalComponentType.Link) {
                    group.style.cursor = `pointer`;
                    group.style.pointerEvents = `auto`;
                }
                const handler = (event) => {
                    const stickyNotes = group.querySelectorAll('[id*="stickyNote"]');
                    const link = group.querySelectorAll('[id*="link"]');
                    const whiteboard = group.querySelectorAll('[id*="whiteboard"]');
                    let type
                    if (stickyNotes.length > 0) {
                        type = 'StickyNote'
                    } else if (link.length > 0) {
                        type = 'Whiteboard'
                    } else if (whiteboard.length > 0) {
                        type = 'Link'
                    }

                    EventBus.emit({ event: ReaderEvent.ClickCanvasSVGObjectEvent, payload: { annotationId, id: group.id, target: event.target ,type} });
                };

                // if (/Android/i.test(userAgent) || /iPhone|iPad|iPod/i.test(userAgent)) {
                //     group.ontouchend = handler;
                // } else {
                group.onpointerup = handler;
                // }
            }
        });

        EventBus.emit({ event: ReaderToolsEvent.SetFabricSVGElement, payload: { fabricSVGElement: node } });

        //svgContent 此參數因需要在小視窗關閉後回到電子書滑鼠狀態並白板連結便利貼互動物件事件需要重新賦予
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [annotationId, userAgent, viewIndex, isActive, svgContent]);

    return (
        <div
            className={classnames(styles.svgContent, styles.canvasSvgContent)}
            ref={canvasSVGRef}
            dangerouslySetInnerHTML={{ __html: svgString }}
        />
    );
};
